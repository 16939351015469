import React from 'react';

const SearchOutlined = (props: any) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00182 8.47086H9.59267L13.3248 12.2104L12.2104 13.3248L8.4708 9.59273V9.00188L8.26886 8.79246C7.41623 9.52542 6.30932 9.96669 5.10517 9.96669C2.42015 9.96669 0.243713 7.79025 0.243713 5.10523C0.243713 2.42021 2.42015 0.243774 5.10517 0.243774C7.79019 0.243774 9.96663 2.42021 9.96663 5.10523C9.96663 6.30938 9.52536 7.4163 8.7924 8.26892L9.00182 8.47086ZM1.73957 5.10523C1.73957 6.96755 3.24288 8.47086 5.1052 8.47086C6.96751 8.47086 8.47082 6.96755 8.47082 5.10523C8.47082 3.24292 6.96751 1.73961 5.1052 1.73961C3.24288 1.73961 1.73957 3.24292 1.73957 5.10523Z"
      fill="currentColor"
    />
  </svg>
);

export default SearchOutlined;
