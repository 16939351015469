import React from 'react';
export default function MoreVertical({ color = 'currentColor', width = '1em', height = '1em' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 9.99996C10.8332 9.53972 10.4601 9.16663 9.99984 9.16663C9.5396 9.16663 9.1665 9.53972 9.1665 9.99996C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 5.00004C10.4601 5.00004 10.8332 4.62694 10.8332 4.16671C10.8332 3.70647 10.4601 3.33337 9.99984 3.33337C9.5396 3.33337 9.1665 3.70647 9.1665 4.16671C9.1665 4.62694 9.5396 5.00004 9.99984 5.00004Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
