import React from 'react';

const Signal = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="10" width="3" height="9" fill="#FF7C2E" />
      <rect x="7" y="5" width="3" height="14" fill="#C4C4C4" />
      <rect x="14" width="3" height="19" fill="#C4C4C4" />
    </svg>
  );
};

export default Signal;
