import React from 'react';

const VideoCamOutlined = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3535 4.76667C14.2522 4.70815 14.1372 4.67735 14.0202 4.67735C13.9031 4.67735 13.7882 4.70815 13.6868 4.76667L11.3335 5.92667C11.3145 5.40905 11.0954 4.91898 10.7224 4.55963C10.3494 4.20027 9.85146 3.99965 9.3335 4H3.3335C2.80306 4 2.29436 4.21071 1.91928 4.58579C1.54421 4.96086 1.3335 5.46957 1.3335 6V10C1.3335 10.5304 1.54421 11.0391 1.91928 11.4142C2.29436 11.7893 2.80306 12 3.3335 12H9.3335C9.85146 12.0003 10.3494 11.7997 10.7224 11.4404C11.0954 11.081 11.3145 10.591 11.3335 10.0733L13.7068 11.26C13.7977 11.3066 13.8981 11.3317 14.0002 11.3333C14.125 11.3337 14.2474 11.2991 14.3535 11.2333C14.4496 11.1733 14.5288 11.0897 14.5836 10.9906C14.6384 10.8914 14.6671 10.78 14.6668 10.6667V5.33333C14.6671 5.22004 14.6384 5.10855 14.5836 5.0094C14.5288 4.91026 14.4496 4.82671 14.3535 4.76667V4.76667ZM10.0002 10C10.0002 10.1768 9.92992 10.3464 9.8049 10.4714C9.67988 10.5964 9.51031 10.6667 9.3335 10.6667H3.3335C3.15669 10.6667 2.98712 10.5964 2.86209 10.4714C2.73707 10.3464 2.66683 10.1768 2.66683 10V6C2.66683 5.82319 2.73707 5.65362 2.86209 5.5286C2.98712 5.40357 3.15669 5.33333 3.3335 5.33333H9.3335C9.51031 5.33333 9.67988 5.40357 9.8049 5.5286C9.92992 5.65362 10.0002 5.82319 10.0002 6V10ZM13.3335 9.58667L11.3335 8.58667V7.41333L13.3335 6.41333V9.58667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VideoCamOutlined;
