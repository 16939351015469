import React from 'react';

const CornerUpLeft = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85641 12.6018L7.68044 13.7829L2.36572 8.49099L7.65757 3.17627L8.83867 4.35224L5.69152 7.51294L14.2796 7.49442C16.1205 7.49045 17.6161 8.97962 17.6201 10.8205L17.6344 17.4872L15.9677 17.4908L15.9534 10.8241C15.9514 9.9037 15.2036 9.15912 14.2832 9.16112L5.42002 9.1802L8.85641 12.6018Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CornerUpLeft;
