import React from 'react';

const Chat = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 10.0626C17.5029 11.1625 17.2459 12.2475 16.75 13.2293C16.162 14.4057 15.2581 15.3952 14.1395 16.087C13.021 16.7788 11.7319 17.1454 10.4167 17.1459C9.31678 17.1488 8.23176 16.8918 7.25 16.3959L2.5 17.9793L4.08333 13.2293C3.58744 12.2475 3.33047 11.1625 3.33333 10.0626C3.33384 8.7474 3.70051 7.45831 4.39227 6.33973C5.08402 5.22115 6.07355 4.31724 7.25 3.72927C8.23176 3.23338 9.31678 2.9764 10.4167 2.97927H10.8333C12.5703 3.0751 14.2109 3.80824 15.4409 5.03832C16.671 6.2684 17.4042 7.90898 17.5 9.64594V10.0626Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chat;
