import React from 'react';

const Sylabbus = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66683 7.9896H9.32308V7.33335V5.66669V5.01044H8.66683H5.3335H4.67725V5.66669V7.33335V7.9896H5.3335H8.66683ZM1.04161 16.6252L0.577574 17.0893L1.04161 16.6252C1.0084 16.592 0.989746 16.547 0.989746 16.5V1.50002C0.989746 1.45306 1.0084 1.40801 1.04161 1.3748C1.07482 1.34159 1.11986 1.32294 1.16683 1.32294H11.1668C11.6558 1.32294 12.1248 1.51719 12.4706 1.86296C12.8163 2.20873 13.0106 2.67769 13.0106 3.16669V14.8334C13.0106 15.3223 12.8163 15.7913 12.4706 16.1371C12.1248 16.4829 11.6558 16.6771 11.1668 16.6771H1.16683C1.11986 16.6771 1.07482 16.6584 1.04161 16.6252ZM1.34391 15.6667V16.3229H2.00016H11.1668C11.5619 16.3229 11.9408 16.166 12.2201 15.8866C12.4995 15.6073 12.6564 15.2284 12.6564 14.8334V3.16669C12.6564 2.77163 12.4995 2.39275 12.2201 2.11339C11.9408 1.83404 11.5619 1.6771 11.1668 1.6771H2.00016H1.34391V2.33335V15.6667ZM4.50016 4.65627H9.50016C9.54713 4.65627 9.59217 4.67493 9.62538 4.70814C9.65859 4.74135 9.67725 4.78639 9.67725 4.83335V8.16669C9.67725 8.21365 9.65859 8.25869 9.62538 8.2919C9.59217 8.32511 9.54713 8.34377 9.50016 8.34377H4.50016C4.4532 8.34377 4.40816 8.32511 4.37495 8.2919C4.34174 8.25869 4.32308 8.21365 4.32308 8.16669V4.83335C4.32308 4.78639 4.34174 4.74135 4.37495 4.70814C4.40816 4.67493 4.4532 4.65627 4.50016 4.65627Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.3125"
      />
    </svg>
  );
};

export default Sylabbus;
