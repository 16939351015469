import React from 'react';

const Check = ({ color = '#36A593', ...rest }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.6649 6.08186C15.5875 6.00376 15.4953 5.94176 15.3938 5.89945C15.2922 5.85715 15.1833 5.83536 15.0733 5.83536C14.9633 5.83536 14.8544 5.85715 14.7528 5.89945C14.6513 5.94176 14.5591 6.00376 14.4816 6.08186L8.27328 12.2985L5.66495 9.68186C5.58451 9.60416 5.48956 9.54307 5.38552 9.50207C5.28147 9.46106 5.17037 9.44095 5.05855 9.44289C4.94673 9.44482 4.83639 9.46876 4.73383 9.51334C4.63126 9.55792 4.53848 9.62226 4.46078 9.7027C4.38308 9.78313 4.32199 9.87808 4.28098 9.98213C4.23998 10.0862 4.21987 10.1973 4.22181 10.3091C4.22374 10.4209 4.24768 10.5313 4.29226 10.6338C4.33684 10.7364 4.40118 10.8292 4.48162 10.9069L7.68162 14.1069C7.75908 14.185 7.85125 14.247 7.9528 14.2893C8.05435 14.3316 8.16327 14.3534 8.27328 14.3534C8.38329 14.3534 8.49221 14.3316 8.59376 14.2893C8.69531 14.247 8.78748 14.185 8.86495 14.1069L15.6649 7.30686C15.7495 7.22883 15.817 7.13412 15.8632 7.0287C15.9094 6.92329 15.9332 6.80945 15.9332 6.69436C15.9332 6.57928 15.9094 6.46544 15.8632 6.36002C15.817 6.25461 15.7495 6.1599 15.6649 6.08186Z"
        fill={color}
      />
    </svg>
  );
};

export default Check;
