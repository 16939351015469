import React from 'react';

const NoteBook = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 3.5H10V4V8.86V9.92752L10.8201 9.24411L11.18 8.94419C11.18 8.94416 11.1801 8.94414 11.1801 8.94411C11.2699 8.86933 11.3831 8.82837 11.5 8.82837C11.6169 8.82837 11.7301 8.86933 11.8199 8.94411C11.8199 8.94414 11.82 8.94416 11.82 8.94419L12.1799 9.24411L13 9.92752V8.86V4V3.5H12.5H10.5ZM9 4V3.5H8.5H5.5C5.10217 3.5 4.72064 3.65804 4.43934 3.93934C4.15804 4.22064 4 4.60217 4 5V15C4 15.3978 4.15804 15.7794 4.43934 16.0607C4.72064 16.342 5.10217 16.5 5.5 16.5H15.5C15.8978 16.5 16.2794 16.342 16.5607 16.0607C16.842 15.7794 17 15.3978 17 15V5C17 4.60217 16.842 4.22064 16.5607 3.93934C16.2794 3.65804 15.8978 3.5 15.5 3.5H14.5H14V4L14 11L14 11.0012C14.0002 11.0969 13.973 11.1906 13.9216 11.2712C13.8711 11.3506 13.7991 11.414 13.7141 11.4543C13.6459 11.4837 13.5725 11.4992 13.4982 11.5C13.3797 11.4996 13.2651 11.4571 13.175 11.38L13.175 11.38L13.1709 11.3766L11.8209 10.2466L11.5 9.97796L11.1791 10.2466L9.82907 11.3766L9.82905 11.3766L9.825 11.38C9.75236 11.4422 9.66344 11.4822 9.56877 11.4953C9.47409 11.5085 9.37764 11.4942 9.29082 11.4542C9.204 11.4142 9.13047 11.3502 9.07893 11.2697C9.02738 11.1892 8.99999 11.0956 9 11V11V4ZM21.1464 6.64645C21.2402 6.55268 21.3674 6.5 21.5 6.5C21.6326 6.5 21.7598 6.55268 21.8536 6.64645C21.9473 6.74021 22 6.86739 22 7V17C22 18.1935 21.5259 19.3381 20.682 20.182C19.8381 21.0259 18.6935 21.5 17.5 21.5H7.5C7.36739 21.5 7.24021 21.4473 7.14645 21.3536C7.05268 21.2598 7 21.1326 7 21C7 20.8674 7.05268 20.7402 7.14645 20.6464C7.24021 20.5527 7.36739 20.5 7.5 20.5H17.5C18.4283 20.5 19.3185 20.1313 19.9749 19.4749C20.6313 18.8185 21 17.9283 21 17V7C21 6.86739 21.0527 6.74021 21.1464 6.64645ZM18 5V15C18 15.663 17.7366 16.2989 17.2678 16.7678C16.7989 17.2366 16.163 17.5 15.5 17.5H5.5C4.83696 17.5 4.20107 17.2366 3.73223 16.7678C3.26339 16.2989 3 15.663 3 15V5C3 4.33696 3.26339 3.70107 3.73223 3.23223C4.20107 2.76339 4.83696 2.5 5.5 2.5H15.5C16.163 2.5 16.7989 2.76339 17.2678 3.23223C17.7366 3.70107 18 4.33696 18 5Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default NoteBook;
