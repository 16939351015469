import React from 'react';

const Heart = ({ color = '#F70000', ...rest }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M16.244 8.67439L16.2544 8.68563L8.87792 16.0621L1.50142 8.68563L1.51185 8.67439C0.655242 7.67573 0.207674 6.39024 0.258831 5.07552C0.309988 3.7608 0.85609 2.51398 1.78771 1.5849C2.71933 0.655819 3.96763 0.113119 5.28249 0.0655474C6.59734 0.0179763 7.8816 0.469049 8.87792 1.32838C9.87425 0.469049 11.1585 0.0179763 12.4734 0.0655474C13.7882 0.113119 15.0365 0.655819 15.9681 1.5849C16.8998 2.51398 17.4459 3.7608 17.497 5.07552C17.5482 6.39024 17.1006 7.67573 16.244 8.67439Z"
      fill={color}
    />
  </svg>
);

export default Heart;
