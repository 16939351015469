import React from 'react';

const Participant = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2124 4.76241C12.2124 7.40617 10.0489 9.52482 7.34933 9.52482C4.6507 9.52482 2.48631 7.40617 2.48631 4.76241C2.48631 2.11865 4.6507 0 7.34933 0C10.0489 0 12.2124 2.11865 12.2124 4.76241ZM0 14.9174C0 12.47 3.38553 11.8577 7.34933 11.8577C11.3347 11.8577 14.6987 12.4911 14.6987 14.9404C14.6987 17.3877 11.3131 18 7.34933 18C3.364 18 0 17.3666 0 14.9174ZM14.1734 4.84875C14.1734 6.19506 13.7605 7.45131 13.0364 8.49482C12.9611 8.60214 13.0276 8.74683 13.1587 8.76983C13.3407 8.79954 13.5276 8.81774 13.7184 8.82158C15.6167 8.87045 17.3202 7.67362 17.7908 5.87118C18.4885 3.19676 16.4415 0.795428 13.8339 0.795428C13.5511 0.795428 13.2801 0.824175 13.0159 0.876878C12.9797 0.884544 12.9405 0.901792 12.921 0.932455C12.8955 0.971743 12.9141 1.02253 12.9396 1.05607C13.7233 2.13216 14.1734 3.44206 14.1734 4.84875ZM17.3173 10.7023C18.5932 10.9466 19.4317 11.444 19.7791 12.1694C20.0736 12.7635 20.0736 13.4534 19.7791 14.0475C19.2478 15.1705 17.5335 15.5318 16.8672 15.6247C16.7292 15.6439 16.6186 15.5289 16.6333 15.3928C16.9738 12.2805 14.2664 10.8048 13.5658 10.4656C13.5364 10.4493 13.5296 10.4263 13.5325 10.411C13.5345 10.4014 13.5472 10.3861 13.5697 10.3832C15.0854 10.3545 16.7155 10.5586 17.3173 10.7023Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Participant;
