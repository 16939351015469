import React from 'react';

const Pause = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.39464 13.728L6.7482 14.0815L6.39464 13.728C6.11334 14.0093 5.73181 14.1673 5.33398 14.1673C4.93616 14.1673 4.55463 14.0093 4.27332 13.728L3.91977 14.0815L4.27332 13.728C3.99202 13.4467 3.83398 13.0651 3.83398 12.6673V3.33398C3.83398 2.93616 3.99202 2.55463 4.27332 2.27332C4.55463 1.99202 4.93616 1.83398 5.33398 1.83398C5.73181 1.83398 6.11334 1.99202 6.39464 2.27332C6.67595 2.55463 6.83398 2.93616 6.83398 3.33398V12.6673C6.83398 13.0651 6.67595 13.4467 6.39464 13.728ZM11.728 2.27332L12.0815 1.91977L11.728 2.27332C12.0093 2.55463 12.1673 2.93616 12.1673 3.33398V12.6673C12.1673 13.0651 12.0093 13.4467 11.728 13.728C11.4467 14.0093 11.0651 14.1673 10.6673 14.1673C10.2695 14.1673 9.88796 14.0093 9.60666 13.728C9.32535 13.4467 9.16732 13.0651 9.16732 12.6673V3.33398C9.16732 2.93616 9.32535 2.55463 9.60666 2.27332C9.88796 1.99202 10.2695 1.83398 10.6673 1.83398C11.0651 1.83398 11.4467 1.99202 11.728 2.27332ZM9.84236 13.4923L10.1959 13.1387L9.84236 13.4923C10.0612 13.7111 10.3579 13.834 10.6673 13.834C10.9767 13.834 11.2735 13.7111 11.4923 13.4923C11.7111 13.2735 11.834 12.9767 11.834 12.6673V3.33398C11.834 3.02457 11.7111 2.72782 11.4923 2.50903C11.2735 2.29023 10.9767 2.16732 10.6673 2.16732C10.3579 2.16732 10.0612 2.29023 9.84236 2.50903C9.62357 2.72782 9.50065 3.02456 9.50065 3.33398V12.6673C9.50065 12.9767 9.62357 13.2735 9.84236 13.4923ZM6.15894 13.4923C6.37773 13.2735 6.50065 12.9767 6.50065 12.6673V3.33398C6.50065 3.02456 6.37773 2.72782 6.15894 2.50903C5.94015 2.29023 5.6434 2.16732 5.33398 2.16732C5.02456 2.16732 4.72782 2.29023 4.50903 2.50903C4.29023 2.72782 4.16732 3.02456 4.16732 3.33398V12.6673C4.16732 12.9767 4.29023 13.2735 4.50903 13.4923C4.72782 13.7111 5.02457 13.834 5.33398 13.834C5.6434 13.834 5.94015 13.7111 6.15894 13.4923Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default Pause;
