import React from 'react';

const DeleteBack = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 2.66675H5.33329L0.666626 8.00008L5.33329 13.3334H14C14.3536 13.3334 14.6927 13.1929 14.9428 12.9429C15.1928 12.6928 15.3333 12.3537 15.3333 12.0001V4.00008C15.3333 3.64646 15.1928 3.30732 14.9428 3.05727C14.6927 2.80722 14.3536 2.66675 14 2.66675V2.66675Z"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6L8 10"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6L12 10"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteBack;
