import React from 'react';

const PlusCircleAlt = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.99984 0.166656C4.84612 0.166656 3.7183 0.508776 2.75901 1.14975C1.79973 1.79073 1.05205 2.70177 0.610543 3.76767C0.169031 4.83357 0.0535119 6.00646 0.278592 7.13802C0.503673 8.26957 1.05924 9.30897 1.87505 10.1248C2.69086 10.9406 3.73026 11.4962 4.86181 11.7212C5.99337 11.9463 7.16626 11.8308 8.23216 11.3893C9.29806 10.9478 10.2091 10.2001 10.8501 9.24082C11.4911 8.28153 11.8332 7.15371 11.8332 5.99999C11.8332 5.23395 11.6823 4.4754 11.3891 3.76767C11.096 3.05994 10.6663 2.41688 10.1246 1.8752C9.58295 1.33353 8.93989 0.903845 8.23216 0.610693C7.52443 0.31754 6.76588 0.166656 5.99984 0.166656V0.166656ZM5.99984 10.6667C5.07686 10.6667 4.17461 10.393 3.40718 9.88018C2.63975 9.3674 2.04161 8.63857 1.6884 7.78585C1.33519 6.93312 1.24278 5.99481 1.42284 5.08957C1.60291 4.18432 2.04736 3.3528 2.70001 2.70016C3.35265 2.04751 4.18417 1.60306 5.08942 1.42299C5.99466 1.24293 6.93297 1.33534 7.7857 1.68855C8.63842 2.04176 9.36725 2.6399 9.88003 3.40733C10.3928 4.17476 10.6665 5.07701 10.6665 5.99999C10.6665 7.23767 10.1748 8.42465 9.29967 9.29982C8.4245 10.175 7.23752 10.6667 5.99984 10.6667V10.6667ZM8.33317 5.41666H6.58317V3.66666C6.58317 3.51195 6.52171 3.36357 6.41232 3.25418C6.30292 3.14478 6.15455 3.08332 5.99984 3.08332C5.84513 3.08332 5.69676 3.14478 5.58736 3.25418C5.47796 3.36357 5.41651 3.51195 5.41651 3.66666V5.41666H3.66651C3.5118 5.41666 3.36342 5.47811 3.25403 5.58751C3.14463 5.69691 3.08317 5.84528 3.08317 5.99999C3.08317 6.1547 3.14463 6.30307 3.25403 6.41247C3.36342 6.52186 3.5118 6.58332 3.66651 6.58332H5.41651V8.33332C5.41651 8.48803 5.47796 8.63641 5.58736 8.7458C5.69676 8.8552 5.84513 8.91666 5.99984 8.91666C6.15455 8.91666 6.30292 8.8552 6.41232 8.7458C6.52171 8.63641 6.58317 8.48803 6.58317 8.33332V6.58332H8.33317C8.48788 6.58332 8.63626 6.52186 8.74565 6.41247C8.85505 6.30307 8.91651 6.1547 8.91651 5.99999C8.91651 5.84528 8.85505 5.69691 8.74565 5.58751C8.63626 5.47811 8.48788 5.41666 8.33317 5.41666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusCircleAlt;
