import React from 'react';

const Analytics = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.16634 10C3.94533 10 3.73337 10.0878 3.57709 10.2441C3.42081 10.4004 3.33301 10.6123 3.33301 10.8334V17.5C3.33301 17.721 3.42081 17.933 3.57709 18.0893C3.73337 18.2456 3.94533 18.3334 4.16634 18.3334C4.38735 18.3334 4.59932 18.2456 4.7556 18.0893C4.91188 17.933 4.99967 17.721 4.99967 17.5V10.8334C4.99967 10.6123 4.91188 10.4004 4.7556 10.2441C4.59932 10.0878 4.38735 10 4.16634 10ZM8.33301 1.66669C8.11199 1.66669 7.90003 1.75448 7.74375 1.91076C7.58747 2.06704 7.49967 2.27901 7.49967 2.50002V17.5C7.49967 17.721 7.58747 17.933 7.74375 18.0893C7.90003 18.2456 8.11199 18.3334 8.33301 18.3334C8.55402 18.3334 8.76598 18.2456 8.92226 18.0893C9.07854 17.933 9.16634 17.721 9.16634 17.5V2.50002C9.16634 2.27901 9.07854 2.06704 8.92226 1.91076C8.76598 1.75448 8.55402 1.66669 8.33301 1.66669ZM16.6663 13.3334C16.4453 13.3334 16.2334 13.4212 16.0771 13.5774C15.9208 13.7337 15.833 13.9457 15.833 14.1667V17.5C15.833 17.721 15.9208 17.933 16.0771 18.0893C16.2334 18.2456 16.4453 18.3334 16.6663 18.3334C16.8874 18.3334 17.0993 18.2456 17.2556 18.0893C17.4119 17.933 17.4997 17.721 17.4997 17.5V14.1667C17.4997 13.9457 17.4119 13.7337 17.2556 13.5774C17.0993 13.4212 16.8874 13.3334 16.6663 13.3334ZM12.4997 6.66669C12.2787 6.66669 12.0667 6.75448 11.9104 6.91077C11.7541 7.06705 11.6663 7.27901 11.6663 7.50002V17.5C11.6663 17.721 11.7541 17.933 11.9104 18.0893C12.0667 18.2456 12.2787 18.3334 12.4997 18.3334C12.7207 18.3334 12.9326 18.2456 13.0889 18.0893C13.2452 17.933 13.333 17.721 13.333 17.5V7.50002C13.333 7.27901 13.2452 7.06705 13.0889 6.91077C12.9326 6.75448 12.7207 6.66669 12.4997 6.66669Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Analytics;
