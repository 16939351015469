import React from 'react';

const X = ({ color = '#FF0000', ...rest }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.0732 5.07349L5.07324 15.0735"
        stroke={color}
        strokeWidth="0.867647"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.07324 5.07349L15.0732 15.0735"
        stroke={color}
        strokeWidth="1.01225"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default X;
