import React from 'react';

const CodeSandbox = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5 15.9999V7.9999C21.4996 7.64918 21.4071 7.30471 21.2315 7.00106C21.056 6.69742 20.8037 6.44526 20.5 6.2699L13.5 2.2699C13.196 2.09437 12.8511 2.00195 12.5 2.00195C12.1489 2.00195 11.804 2.09437 11.5 2.2699L4.5 6.2699C4.19626 6.44526 3.94398 6.69742 3.76846 7.00106C3.59294 7.30471 3.50036 7.64918 3.5 7.9999V15.9999C3.50036 16.3506 3.59294 16.6951 3.76846 16.9987C3.94398 17.3024 4.19626 17.5545 4.5 17.7299L11.5 21.7299C11.804 21.9054 12.1489 21.9979 12.5 21.9979C12.8511 21.9979 13.196 21.9054 13.5 21.7299L20.5 17.7299C20.8037 17.5545 21.056 17.3024 21.2315 16.9987C21.4071 16.6951 21.4996 16.3506 21.5 15.9999Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 12L17 14.6V19.79"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 19.79V14.6L3.5 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.76953 6.95996L12.4995 12.01L21.2295 6.95996"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 22.08V12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.20996L12.5 6.80996L17 4.20996"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CodeSandbox;
