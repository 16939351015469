import React from 'react';

const Swap = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9166 10.0686C13.3492 9.04084 13.4488 7.90331 13.2013 6.81599C12.9539 5.72867 12.3719 4.74623 11.5372 4.00681C10.7025 3.26738 9.65706 2.80815 8.54784 2.69368C7.43861 2.57921 6.3214 2.81526 5.35328 3.36864L4.69194 2.21064C5.7037 1.63238 6.8494 1.32955 8.01475 1.33236C9.18009 1.33516 10.3243 1.64351 11.3333 2.22664C14.3266 3.95464 15.4733 7.65464 14.0779 10.74L14.9726 11.256L12.1959 12.732L12.0859 9.5893L12.9166 10.0686ZM3.08328 5.9313C2.65071 6.9591 2.55113 8.09663 2.79856 9.18395C3.04598 10.2713 3.62795 11.2537 4.46266 11.9931C5.29737 12.7326 6.34283 13.1918 7.45205 13.3063C8.56128 13.4207 9.67849 13.1847 10.6466 12.6313L11.3079 13.7893C10.2962 14.3676 9.15049 14.6704 7.98514 14.6676C6.8198 14.6648 5.67557 14.3564 4.66661 13.7733C1.67328 12.0453 0.526611 8.3453 1.92194 5.25997L1.02661 4.74464L3.80328 3.26864L3.91328 6.4113L3.08261 5.93197L3.08328 5.9313ZM8.94328 9.8853L7.05594 7.99997L5.17061 9.8853L4.22794 8.94264L7.05661 6.11464L8.94261 7.99997L10.8286 6.11464L11.7713 7.0573L8.94261 9.8853H8.94328Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Swap;
