import React from 'react';

const VerticalAlignCenter = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 12.6667H22V5.99999C22 5.64637 21.8595 5.30723 21.6095 5.05718C21.3594 4.80713 21.0203 4.66666 20.6667 4.66666H16.6667V1.99999C16.6667 1.64637 16.5262 1.30723 16.2761 1.05718C16.0261 0.807132 15.687 0.666656 15.3333 0.666656H7.33333C6.97971 0.666656 6.64057 0.807132 6.39053 1.05718C6.14048 1.30723 6 1.64637 6 1.99999V12.6667H2C1.64638 12.6667 1.30724 12.8071 1.05719 13.0572C0.807144 13.3072 0.666668 13.6464 0.666668 14C0.666668 14.3536 0.807144 14.6928 1.05719 14.9428C1.30724 15.1928 1.64638 15.3333 2 15.3333H6V26C6 26.3536 6.14048 26.6928 6.39053 26.9428C6.64057 27.1929 6.97971 27.3333 7.33333 27.3333H15.3333C15.687 27.3333 16.0261 27.1929 16.2761 26.9428C16.5262 26.6928 16.6667 26.3536 16.6667 26V23.3333H20.6667C21.0203 23.3333 21.3594 23.1928 21.6095 22.9428C21.8595 22.6928 22 22.3536 22 22V15.3333H26C26.3536 15.3333 26.6928 15.1928 26.9428 14.9428C27.1929 14.6928 27.3333 14.3536 27.3333 14C27.3333 13.6464 27.1929 13.3072 26.9428 13.0572C26.6928 12.8071 26.3536 12.6667 26 12.6667ZM14 24.6667H8.66667V3.33332H14V24.6667ZM19.3333 20.6667H16.6667V7.33332H19.3333V20.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VerticalAlignCenter;
