import React from 'react';

const LockSolid = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.91634 4.24996V3.08329C7.91634 2.30974 7.60905 1.56788 7.06207 1.0209C6.51509 0.473917 5.77322 0.166626 4.99967 0.166626C4.22613 0.166626 3.48426 0.473917 2.93728 1.0209C2.3903 1.56788 2.08301 2.30974 2.08301 3.08329V4.24996C1.61888 4.24996 1.17376 4.43433 0.845571 4.76252C0.517382 5.09071 0.333008 5.53583 0.333008 5.99996V10.0833C0.333008 10.5474 0.517382 10.9925 0.845571 11.3207C1.17376 11.6489 1.61888 11.8333 2.08301 11.8333H7.91634C8.38047 11.8333 8.82559 11.6489 9.15378 11.3207C9.48197 10.9925 9.66634 10.5474 9.66634 10.0833V5.99996C9.66634 5.53583 9.48197 5.09071 9.15378 4.76252C8.82559 4.43433 8.38047 4.24996 7.91634 4.24996ZM3.24967 3.08329C3.24967 2.61916 3.43405 2.17404 3.76224 1.84586C4.09043 1.51767 4.53555 1.33329 4.99967 1.33329C5.4638 1.33329 5.90892 1.51767 6.23711 1.84586C6.5653 2.17404 6.74967 2.61916 6.74967 3.08329V4.24996H3.24967V3.08329ZM8.49967 10.0833C8.49967 10.238 8.43822 10.3864 8.32882 10.4958C8.21942 10.6052 8.07105 10.6666 7.91634 10.6666H2.08301C1.9283 10.6666 1.77993 10.6052 1.67053 10.4958C1.56113 10.3864 1.49967 10.238 1.49967 10.0833V5.99996C1.49967 5.84525 1.56113 5.69688 1.67053 5.58748C1.77993 5.47808 1.9283 5.41663 2.08301 5.41663H7.91634C8.07105 5.41663 8.21942 5.47808 8.32882 5.58748C8.43822 5.69688 8.49967 5.84525 8.49967 5.99996V10.0833Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LockSolid;
