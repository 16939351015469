import React from 'react';

const GameControl = (props: any) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1668 0.048584C15.4929 0.048584 16.7647 0.575368 17.7024 1.51305C18.64 2.45073 19.1668 3.7225 19.1668 5.04858V8.38192C19.1668 9.708 18.64 10.9798 17.7024 11.9175C16.7647 12.8551 15.4929 13.3819 14.1668 13.3819H5.8335C4.50741 13.3819 3.23564 12.8551 2.29796 11.9175C1.36028 10.9798 0.833496 9.708 0.833496 8.38192V5.04858C0.833496 3.7225 1.36028 2.45073 2.29796 1.51305C3.23564 0.575368 4.50741 0.048584 5.8335 0.048584H14.1668ZM8.33349 4.21525H6.66683V5.88192H5.00016V7.54858H6.666L6.66683 9.21525H8.33349L8.33266 7.54858H10.0002V5.88192H8.33349V4.21525ZM15.0002 7.54858H13.3335V9.21525H15.0002V7.54858ZM13.3335 4.21525H11.6668V5.88192H13.3335V4.21525Z"
      fill="currentColor"
    />
  </svg>
);

export default GameControl;
