import React from 'react';

const BookClose = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6564 2.33335V1.6771H12.0002H3.66683C3.05075 1.6771 2.45991 1.92184 2.02428 2.35747C1.58865 2.7931 1.34391 3.38394 1.34391 4.00002V11.1334V12.2943L2.33859 11.6956C2.74018 11.4539 3.19959 11.3251 3.66829 11.3229H12.0002H12.6564V10.6667V2.33335ZM12.6564 12.3334V11.6771H12.0002H3.66683C3.05075 11.6771 2.45991 11.9218 2.02428 12.3575C1.58865 12.7931 1.34391 13.3839 1.34391 14C1.34391 14.6161 1.58865 15.2069 2.02428 15.6426C2.45991 16.0782 3.05075 16.3229 3.66683 16.3229H12.0002H12.6564V15.6667V12.3334ZM3.66683 1.32294H12.0002C12.2681 1.32294 12.5251 1.42939 12.7146 1.61888C12.9041 1.80837 13.0106 2.06537 13.0106 2.33335V15.6667C13.0106 15.9347 12.9041 16.1917 12.7146 16.3812C12.5251 16.5706 12.2681 16.6771 12.0002 16.6771H3.66683C2.95682 16.6771 2.2759 16.3951 1.77385 15.893C1.27179 15.391 0.989746 14.71 0.989746 14V4.00002C0.989746 3.29001 1.2718 2.60909 1.77385 2.10704C2.2759 1.60499 2.95682 1.32294 3.66683 1.32294ZM8.66683 5.01044H5.3335C5.28653 5.01044 5.24149 4.99178 5.20828 4.95857C5.17507 4.92536 5.15641 4.88032 5.15641 4.83335C5.15641 4.78639 5.17507 4.74135 5.20828 4.70814C5.24149 4.67493 5.28653 4.65627 5.3335 4.65627H8.66683C8.71379 4.65627 8.75884 4.67493 8.79205 4.70814C8.82526 4.74135 8.84391 4.78639 8.84391 4.83335C8.84391 4.88032 8.82526 4.92536 8.79205 4.95857C8.75884 4.99178 8.71379 5.01044 8.66683 5.01044Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.3125"
      />
    </svg>
  );
};

export default BookClose;
