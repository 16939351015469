import React from 'react';

const VideoCamOffOutlined = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.1065 3.33333H9.33317C9.68679 3.33333 10.0259 3.4738 10.276 3.72385C10.526 3.9739 10.6665 4.31304 10.6665 4.66666V6.89333L11.3332 7.56L15.3332 4.66666V11.3333M10.6665 10.6667V11.3333C10.6665 11.687 10.526 12.0261 10.276 12.2761C10.0259 12.5262 9.68679 12.6667 9.33317 12.6667H1.99984C1.64622 12.6667 1.30708 12.5262 1.05703 12.2761C0.80698 12.0261 0.666504 11.687 0.666504 11.3333V4.66666C0.666504 4.31304 0.80698 3.9739 1.05703 3.72385C1.30708 3.4738 1.64622 3.33333 1.99984 3.33333H3.33317L10.6665 10.6667Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666504 0.666672L15.3332 15.3333"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoCamOffOutlined;
