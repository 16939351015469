import React from 'react';

const CalendarIcon = ({ ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6666 2.66667H11.3333V2C11.3333 1.82319 11.2631 1.65362 11.1381 1.5286C11.013 1.40357 10.8435 1.33333 10.6666 1.33333C10.4898 1.33333 10.3203 1.40357 10.1952 1.5286C10.0702 1.65362 9.99998 1.82319 9.99998 2V2.66667L5.99998 2.66667V2C5.99998 1.82319 5.92974 1.65362 5.80472 1.5286C5.67969 1.40357 5.51012 1.33333 5.33331 1.33333C5.1565 1.33333 4.98693 1.40357 4.86191 1.5286C4.73688 1.65362 4.66665 1.82319 4.66665 2V2.66667H3.33331C2.80288 2.66667 2.29417 2.87738 1.9191 3.25245C1.54403 3.62753 1.33331 4.13623 1.33331 4.66667L1.33331 12.6667C1.33331 13.1971 1.54403 13.7058 1.9191 14.0809C2.29417 14.456 2.80288 14.6667 3.33331 14.6667H12.6666C13.1971 14.6667 13.7058 14.456 14.0809 14.0809C14.4559 13.7058 14.6666 13.1971 14.6666 12.6667V4.66667C14.6666 4.13623 14.4559 3.62753 14.0809 3.25245C13.7058 2.87738 13.1971 2.66667 12.6666 2.66667ZM13.3333 12.6667C13.3333 12.8435 13.2631 13.013 13.1381 13.1381C13.013 13.2631 12.8435 13.3333 12.6666 13.3333H3.33331C3.1565 13.3333 2.98693 13.2631 2.86191 13.1381C2.73688 13.013 2.66665 12.8435 2.66665 12.6667L2.66665 8H13.3333V12.6667ZM13.3333 6.66667L2.66665 6.66667V4.66667C2.66665 4.48986 2.73688 4.32029 2.86191 4.19526C2.98693 4.07024 3.1565 4 3.33331 4H4.66665V4.66667C4.66665 4.84348 4.73688 5.01305 4.86191 5.13807C4.98693 5.2631 5.1565 5.33333 5.33331 5.33333C5.51012 5.33333 5.67969 5.2631 5.80472 5.13807C5.92974 5.01305 5.99998 4.84348 5.99998 4.66667V4H9.99998V4.66667C9.99998 4.84348 10.0702 5.01305 10.1952 5.13807C10.3203 5.2631 10.4898 5.33333 10.6666 5.33333C10.8435 5.33333 11.013 5.2631 11.1381 5.13807C11.2631 5.01305 11.3333 4.84348 11.3333 4.66667V4L12.6666 4C12.8435 4 13.013 4.07024 13.1381 4.19526C13.2631 4.32029 13.3333 4.48986 13.3333 4.66667V6.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CalendarIcon;
