import React from 'react';

const ArrowRight = ({ ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.0202 0.646447C6.82494 0.451184 6.50836 0.451184 6.31309 0.646447C6.11783 0.841709 6.11783 1.15829 6.31309 1.35355L7.0202 0.646447ZM10.1952 4.5286L9.84169 4.88215L9.84169 4.88215L10.1952 4.5286ZM10.1952 5.4714L9.84169 5.11785L9.84169 5.11785L10.1952 5.4714ZM6.31309 8.64645C6.11783 8.84171 6.11783 9.15829 6.31309 9.35355C6.50836 9.54882 6.82494 9.54882 7.0202 9.35355L6.31309 8.64645ZM9.99998 5.5C10.2761 5.5 10.5 5.27614 10.5 5C10.5 4.72386 10.2761 4.5 9.99998 4.5L9.99998 5.5ZM1.33331 4.5C1.05717 4.5 0.833313 4.72386 0.833313 5C0.833313 5.27614 1.05717 5.5 1.33331 5.5L1.33331 4.5ZM6.31309 1.35355L9.84169 4.88215L10.5488 4.17504L7.0202 0.646447L6.31309 1.35355ZM9.84169 5.11785L6.31309 8.64645L7.0202 9.35355L10.5488 5.82496L9.84169 5.11785ZM9.84169 4.88215C9.90678 4.94724 9.90678 5.05276 9.84169 5.11785L10.5488 5.82496C11.0044 5.36935 11.0044 4.63065 10.5488 4.17504L9.84169 4.88215ZM9.99998 4.5L1.33331 4.5L1.33331 5.5L9.99998 5.5L9.99998 4.5Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRight;
