import React from 'react';

const AttendanceIcon = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.08517 7.44681V6.80851H8.93623V7.44681C8.93623 7.67253 9.0259 7.889 9.1855 8.0486C9.34511 8.20821 9.56158 8.29787 9.7873 8.29787H11.0639C11.2896 8.29787 11.5061 8.20821 11.6657 8.0486C11.8253 7.889 11.915 7.67253 11.915 7.44681V6.59575C11.915 6.37003 11.8253 6.15356 11.6657 5.99395C11.5061 5.83435 11.2896 5.74468 11.0639 5.74468H9.7873C9.58189 5.74601 9.3839 5.82158 9.22985 5.95745H7.79155C7.6375 5.82158 7.43951 5.74601 7.2341 5.74468H5.95751C5.73179 5.74468 5.51532 5.83435 5.35572 5.99395C5.19611 6.15356 5.10645 6.37003 5.10645 6.59575V7.44681C5.10645 7.67253 5.19611 7.889 5.35572 8.0486C5.51532 8.20821 5.73179 8.29787 5.95751 8.29787H7.2341C7.45982 8.29787 7.67629 8.20821 7.8359 8.0486C7.9955 7.889 8.08517 7.67253 8.08517 7.44681ZM9.7873 6.59575H11.0639V7.44681H9.7873V6.59575ZM5.95751 6.59575H7.2341V7.44681H5.95751V6.59575Z"
          fill="currentColor"
        />
        <path
          d="M9.14891 9.57447H7.87231C7.75945 9.57447 7.65122 9.6193 7.57141 9.69911C7.49161 9.77891 7.44678 9.88714 7.44678 10C7.44678 10.1129 7.49161 10.2211 7.57141 10.3009C7.65122 10.3807 7.75945 10.4255 7.87231 10.4255H9.14891C9.26176 10.4255 9.37 10.3807 9.4498 10.3009C9.52961 10.2211 9.57444 10.1129 9.57444 10C9.57444 9.88714 9.52961 9.77891 9.4498 9.69911C9.37 9.6193 9.26176 9.57447 9.14891 9.57447Z"
          fill="currentColor"
        />
        <path
          d="M3.61698 15.6809C3.61962 15.3655 3.6847 15.0539 3.80847 14.7638C3.48033 14.5054 3.17365 14.2207 2.89145 13.9128C3.44959 13.6372 4.04208 13.4376 4.65315 13.3192C5.06181 13.2414 5.44285 13.0576 5.75803 12.7861C6.07322 12.5146 6.31147 12.165 6.4489 11.7723C7.03217 12.2495 7.75729 12.5197 8.5106 12.5404C9.26388 12.5219 9.98974 12.254 10.5744 11.7787C10.643 11.9488 10.7263 12.1125 10.8234 12.2681L11.4425 11.6298C11.3638 11.4741 11.301 11.3109 11.2553 11.1426C12.3936 9.9 13.1085 8.06596 13.1085 6.67447C13.1085 4.2532 11.0446 2.28511 8.5106 2.28511C5.97656 2.28511 3.91273 4.26809 3.91273 6.68937C3.91273 8.07234 4.62762 9.90639 5.76805 11.1574C5.64039 11.6106 5.31911 12.3404 4.5106 12.4872C3.75308 12.6308 3.02149 12.8876 2.34039 13.2489C1.63508 12.2858 1.16467 11.1711 0.966685 9.99386C0.768701 8.81661 0.848628 7.60938 1.20009 6.4685C1.55155 5.32763 2.16481 4.2847 2.99094 3.42294C3.81707 2.56118 4.83319 1.90444 5.95821 1.50515C7.08323 1.10585 8.28602 0.975044 9.47057 1.12317C10.6551 1.27129 11.7887 1.69424 12.7807 2.35827C13.7728 3.02229 14.5959 3.90901 15.1844 4.94766C15.7729 5.98631 16.1105 7.14814 16.1702 8.34043C16.4743 8.39995 16.7636 8.51928 17.0212 8.69149C17.0167 7.23079 16.6363 5.79584 15.9166 4.52472C15.1969 3.25361 14.1622 2.18912 12.912 1.4337C11.6618 0.678286 10.2382 0.257364 8.77818 0.211444C7.31819 0.165525 5.87096 0.496153 4.57575 1.17151C3.28054 1.84687 2.18096 2.84423 1.38281 4.0676C0.58467 5.29098 0.114835 6.69919 0.0185222 8.15672C-0.0777907 9.61425 0.202661 11.072 0.832887 12.3898C1.46311 13.7076 2.4219 14.8409 3.61698 15.6809ZM4.76166 6.68937C4.76166 4.7383 6.44251 3.15107 8.50847 3.15107C10.5744 3.15107 12.2553 4.7383 12.2553 6.68937C12.2553 8.64043 10.5425 11.6894 8.5106 11.6894C6.47868 11.6894 4.76379 8.61702 4.76379 6.68937H4.76166Z"
          fill="currentColor"
        />
        <path
          d="M16.7935 9.60212C16.6525 9.45854 16.4843 9.34448 16.2987 9.26663C16.1132 9.18877 15.9139 9.14867 15.7127 9.14867C15.5114 9.14867 15.3122 9.18877 15.1266 9.26663C14.941 9.34448 14.7728 9.45854 14.6318 9.60212L8.47439 15.8723L7.14247 14.517C5.72758 13.0745 3.53609 15.3808 4.90843 16.7766L7.49779 19.4149C7.61369 19.5333 7.75206 19.6273 7.90477 19.6915C8.05749 19.7557 8.22149 19.7888 8.38715 19.7888C8.55282 19.7888 8.71682 19.7557 8.86953 19.6915C9.02225 19.6273 9.16061 19.5333 9.27651 19.4149L16.7935 11.7553C17.0733 11.4667 17.2297 11.0806 17.2297 10.6787C17.2297 10.2768 17.0733 9.8907 16.7935 9.60212ZM16.1872 11.1638L8.67013 18.8234C8.63346 18.861 8.58962 18.891 8.5412 18.9114C8.49278 18.9318 8.44077 18.9423 8.38822 18.9423C8.33567 18.9423 8.28365 18.9318 8.23523 18.9114C8.18681 18.891 8.14297 18.861 8.1063 18.8234L5.51694 16.1702C4.92545 15.5681 5.88503 14.4383 6.53609 15.1064L8.19354 16.8085C8.23021 16.8461 8.27405 16.8761 8.32247 16.8965C8.37088 16.9169 8.4229 16.9274 8.47545 16.9274C8.528 16.9274 8.58002 16.9169 8.62844 16.8965C8.67685 16.8761 8.72069 16.8461 8.75737 16.8085L15.2403 10.2128C15.3666 10.0878 15.5371 10.0177 15.7148 10.0177C15.8925 10.0177 16.063 10.0878 16.1893 10.2128C16.3102 10.3417 16.3773 10.512 16.3769 10.6887C16.3765 10.8655 16.3086 11.0354 16.1872 11.1638Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="17.234" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AttendanceIcon;
